import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import { get } from 'idb-keyval';
import realmConnection from '@/views/habit/realm'

export default function useProcessList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const { invokeFunction, updateItem, updateItems } = realmConnection()
  const { client = null, role = null, worker_id = null } = store.state?.userStore?.userData
  const processes = ref([])
  const isOnline = computed(() => store.state.app.isOnline)
  const collection = 'process'

  // Table Headers
  const tableColumns = computed(() => {
    return [
      { key: 'code', label: i18n.t('message.tableHeader.code'), sortable: true },
      { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
      { key: 'activities', label: i18n.t('message.tableHeader.activities'), sortable: true },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })
  const totalProcesses = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const defaultSortBy = 'name'
  const sortBy = ref(defaultSortBy)
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const fetchProcesses = async () => {
    isLoading.value = true

    if (isOnline.value) {
      try {
        const input = {
          limit: perPage.value,
          total: currentPage.value === 1,
          offset: (currentPage.value * perPage.value) - perPage.value,
          query: {},
          search: searchQuery.value,
          sortBy: sortBy.value || defaultSortBy,
          sortOrder: isSortDirDesc.value ? -1 : 1,
          client_id: (role !== 'admin') ? client.$oid : null,
          worker_id: (role !== 'admin') ? worker_id.$oid : null,
          role: role,
        }
      
        const items = await invokeFunction({ name: 'processesQueryWithOffset', arg: input })

        processes.value = items?.processes || []
        if (currentPage.value === 1) totalProcesses.value = items?.totalRecords || 0
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.process_list_fetch_error') })
      } finally {
        isLoading.value = false
      }
    }
    else {
      get('processesComplete').then(response => {
        if (response) processes.value = response
        else if (localStorage.processesComplete) processes.value = JSON.parse(localStorage.processesComplete)
        else handleError({ isStorageError: true })
        isLoading.value = false
      })
    }
  }

  const deleteProcess = async (id) => {
    try {
      const query = { _id: id }
      const action = { $set: { deleted: true } }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.process_remove'))
      fetchProcesses()

      // Delete confirmation plannings associated to this process
      await updateItems({ collection: 'confirmation_planning', query: { process: id }, action })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.process_remove_error'))
    }
  }

  // TODO::Nipun - Make global mixin and import it or extend all composable with that
  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (processes.value.length ? 1 : 0)
    const to = processes.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalProcesses.value })
  })

  watch([currentPage, perPage], () => {
    fetchProcesses()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchProcesses()
  })

  return {
    fetchProcesses,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    totalProcesses,
    showingMessage,
    searchQuery,
    sortBy,
    isSortDirDesc,
    processes,
    deleteProcess,
    isLoading,
  }
}
